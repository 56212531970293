import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  formatSelectedDateToViewFormat,
  getLocalTimezone,
} from '../../../../utils/dateAndTime/dateAndTime';
import {
  createNoAvailableSlotsViewModel,
  NoAvailableSlotsViewModel,
} from '../noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import {
  createTimeSelectionViewModel,
  TimeSelectionViewModel,
} from '../timeSelectionViewModel/timeSelectionViewModel';
import {
  createTimezoneSelectionViewModel,
  TimezoneSelectionViewModel,
} from '../timezoneSelectionViewModel/timezoneSelectionViewModel';

export enum TimePickerStatus {
  LOADING = 'LOADING',
  NO_AVAILABLE_SLOTS_FOR_SELECTED_DATE = 'NO_AVAILABLE_SLOTS_FOR_SELECTED_DATE',
  LOADED = 'LOADED',
}

export type TimePickerViewModel = {
  status: TimePickerStatus;
  noAvailableSlotsViewModel: NoAvailableSlotsViewModel;
  timeSelectionViewModel: TimeSelectionViewModel;
  timezoneSelectionViewModel?: TimezoneSelectionViewModel;
  selectedDate?: string;
};

export function createTimePickerViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): TimePickerViewModel {
  const { selectedDate, timePickerStatus } = state;
  const { businessInfo } = context;

  const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale;
  const formattedSelectedDate =
    selectedDate &&
    formatSelectedDateToViewFormat(selectedDate, dateRegionalSettingsLocale);

  const noAvailableSlotsViewModel: NoAvailableSlotsViewModel = createNoAvailableSlotsViewModel(
    {
      state,
      context,
    },
  );

  const timeSelectionViewModel = createTimeSelectionViewModel({
    state,
    context,
  });

  let timezoneSelectionViewModel: TimezoneSelectionViewModel | undefined;
  const shouldShowTimezoneSelection =
    selectedDate && getLocalTimezone() !== businessInfo.timeZone;
  if (shouldShowTimezoneSelection) {
    timezoneSelectionViewModel = createTimezoneSelectionViewModel({
      state,
      context,
    });
  }

  return {
    status: timePickerStatus,
    selectedDate: formattedSelectedDate,
    noAvailableSlotsViewModel,
    timeSelectionViewModel,
    ...(timezoneSelectionViewModel ? { timezoneSelectionViewModel } : {}),
  };
}
