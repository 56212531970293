import { CalendarBiLogger } from '../../../../utils/bi/biLoggerFactory';
import {
  CalendarErrors,
  TriggeredByOptions,
} from '../../../../utils/bi/consts';
import {
  SelectableOption,
  SelectedOptions,
} from '../../../../utils/selectableOptions/selectableOptions';

export const updateCalendarErrors = (
  calendarErrors: CalendarErrors[],
  selectableStaffMembers: SelectableOption[],
  selectableLocations: SelectableOption[],
  selectableDurations: SelectableOption[],
  biLogger: CalendarBiLogger,
  selectedTime: string,
  selectedOptions?: SelectedOptions,
) => {
  updateCalendarError(
    calendarErrors,
    selectableStaffMembers,
    CalendarErrors.BOOKING_DETAILS_NO_SELECTED_STAFF_MEMBER,
    biLogger,
    selectedTime,
    selectedOptions?.staffMember,
  );
  updateCalendarError(
    calendarErrors,
    selectableLocations,
    CalendarErrors.BOOKING_DETAILS_NO_SELECTED_LOCATION,
    biLogger,
    selectedTime,
    selectedOptions?.location,
  );
  updateCalendarError(
    calendarErrors,
    selectableDurations,
    CalendarErrors.BOOKING_DETAILS_NO_SELECTED_DURATION,
    biLogger,
    selectedTime,
    selectedOptions?.duration,
  );
  return calendarErrors;
};
const updateCalendarError = (
  calendarErrors: CalendarErrors[],
  selectableOption: SelectableOption[],
  error: CalendarErrors,
  biLogger: CalendarBiLogger,
  selectedTime: string,
  selectedOption?: string,
) => {
  const isDropdown = selectableOption.length > 1;
  const isDropdownSelected = selectedOption;
  const isErrorExistOnCalendarErrors = calendarErrors.indexOf(error) !== -1;

  if (isDropdown && !isDropdownSelected && !isErrorExistOnCalendarErrors) {
    calendarErrors.push(error);
    biLogger.bookingsCalendarBookingDetailsLoad({
      triggeredBy: TriggeredByOptions.SUBMIT,
      userMessage: error,
      selectedSlot: selectedTime,
    });
  }
};
