import { ActionFactoryParams } from '../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../controller';
import { CalendarContext } from '../../../utils/context/contextFactory';
import {
  createInitializeWidgetAction,
  InitializeWidgetAction,
} from './initializeWidgetAction/initializeWidgetAction';
import {
  createOnDateSelectedAction,
  OnDateSelectedAction,
} from './onDateSelectedAction/onDateSelectedAction';
import {
  createGoToNextAvailableDateAction,
  GoToNextAvailableDateAction,
} from './goToNextAvailableDateAction/goToNextAvailableDateAction';
import {
  createOnMonthSelectedAction,
  OnMonthSelectedAction,
} from './onMonthSelectedAction/onMonthSelectedAction';
import {
  createOnTimeSelectedAction,
  OnTimeSelectedAction,
} from './onTimeSelectedAction/onTimeSelectedAction';
import {
  createOnSlotOptionSelectedAction,
  OnSlotOptionSelectedAction,
} from './onSlotOptionSelectedAction/onSlotOptionSelectedAction';
import {
  createOnTimezoneSelectedAction,
  OnTimezoneSelectedAction,
} from './onTimezoneSelectedAction/onTimezoneSelectedAction';
import {
  createOnSubmitAction,
  OnSubmitAction,
} from './onSubmitAction/onSubmitAction';
import { createRefetchPlatformDataAction } from './refetchPlatformDataAction/refetchPlatformDataAction';
import {
  createSetSelectedTimeAction,
  SetSelectedTimeAction,
} from './setSelectedTimeAction/setSelectedTimeAction';

export type CalendarActions = {
  initializeWidgetAction: InitializeWidgetAction;
  onDateSelectedAction: OnDateSelectedAction;
  goToNextAvailableDateAction: GoToNextAvailableDateAction;
  onMonthSelectedAction: OnMonthSelectedAction;
  onTimeSelectedAction: OnTimeSelectedAction;
  onSlotOptionSelectedAction: OnSlotOptionSelectedAction;
  onSubmitAction: OnSubmitAction;
  onTimezoneSelectedAction: OnTimezoneSelectedAction;
};

export const createCalendarActions = (
  actionFactoryParams: ActionFactoryParams<CalendarState, CalendarContext>,
): CalendarActions => {
  const onDateSelectedAction = createOnDateSelectedAction(actionFactoryParams);
  const onMonthSelectedAction = createOnMonthSelectedAction(
    actionFactoryParams,
  );
  const refetchPlatformDataAction = createRefetchPlatformDataAction(
    actionFactoryParams,
    onDateSelectedAction,
    onMonthSelectedAction,
  );
  const setSelectedTimeAction = createSetSelectedTimeAction(
    actionFactoryParams,
  );
  return {
    initializeWidgetAction: createInitializeWidgetAction(
      actionFactoryParams,
      onDateSelectedAction,
      onMonthSelectedAction,
    ),
    onDateSelectedAction,
    goToNextAvailableDateAction: createGoToNextAvailableDateAction(
      actionFactoryParams,
      onDateSelectedAction,
    ),
    onMonthSelectedAction,
    onTimeSelectedAction: createOnTimeSelectedAction(
      actionFactoryParams,
      setSelectedTimeAction,
    ),
    onSlotOptionSelectedAction: createOnSlotOptionSelectedAction(
      actionFactoryParams,
    ),
    onSubmitAction: createOnSubmitAction(actionFactoryParams),
    onTimezoneSelectedAction: createOnTimezoneSelectedAction(
      actionFactoryParams,
      refetchPlatformDataAction,
      setSelectedTimeAction,
    ),
  };
};
