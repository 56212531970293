import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  formatTimezone,
  getLocalTimezone,
} from '../../../../utils/dateAndTime/dateAndTime';
import { BusinessInfoBase } from '@wix/bookings-uou-types';

export type TimezoneSelectionViewModel = {
  selectableTimezones: TimezoneOption[];
  selectedTimezone: TimezoneOption;
  timezoneLabel: string;
};

export type TimezoneOption = {
  formattedTimezone: string;
  value: string;
};

export function createTimezoneSelectionViewModel({
  context,
  state,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): TimezoneSelectionViewModel {
  const { businessInfo, t } = context;
  const selectedDate = state.selectedDate!;
  const locale = businessInfo.dateRegionalSettingsLocale!;

  const selectableTimezones = getSelectableTimezones(
    selectedDate,
    businessInfo,
  );
  const selectedTimezone = getTimezoneOption(
    selectedDate,
    state.selectedTimezone!,
    locale,
  );

  return {
    selectableTimezones,
    selectedTimezone,
    timezoneLabel: t('app.timezone-selection.timezone-label'),
  };
}

function getSelectableTimezones(
  selectedDate: string,
  businessInfo: BusinessInfoBase,
): TimezoneOption[] {
  const locale = businessInfo.dateRegionalSettingsLocale!;
  if (businessInfo.timezoneProperties?.clientCanChooseTimezone) {
    return businessInfo.timeZone! !== getLocalTimezone()
      ? [
          getTimezoneOption(selectedDate, businessInfo.timeZone!, locale),
          getTimezoneOption(selectedDate, getLocalTimezone(), locale),
        ]
      : [];
  }
  return [];
}

function getTimezoneOption(
  selectedDate: string,
  timezone: string,
  locale: string,
): TimezoneOption {
  return {
    value: timezone,
    formattedTimezone: formatTimezone(selectedDate, timezone, locale),
  };
}
