import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { NotificationStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';

export type SetSelectedTimeAction = (selectedTime: string | undefined) => void;

export function createSetSelectedTimeAction({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>): SetSelectedTimeAction {
  return async (selectedTime: string | undefined) => {
    const [state, setState] = getControllerState();
    const { availableSlots } = state;

    let selectableSlots;
    if (selectedTime) {
      selectableSlots = availableSlots?.availabilityEntries?.filter(
        (availableSlot) => {
          return availableSlot.slot?.start === selectedTime;
        },
      );
    }

    setState({
      calendarErrors: [],
      selectedOptions: {},
      selectableSlots: selectableSlots ?? [],
      selectedTime,
      notificationStatus: NotificationStatus.NONE,
    });
  };
}
