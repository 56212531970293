import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import {
  OfferedAsType,
  ServicePayment,
  ServicePaymentDto,
} from '@wix/bookings-uou-types';
import {
  PaymentDtoMapper,
  isServiceOfferedAsPricingPlan,
} from '@wix/bookings-uou-mappers';
import { formatRFCtoDateAndTime } from '../../../../utils/dateAndTime/dateAndTime';
import {
  getSelectables,
  SelectableOption,
} from '../../../../utils/selectableOptions/selectableOptions';
import { CalendarErrors, Dropdowns } from '../../../../utils/bi/consts';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import settingsParams from '../../settingsParams';

export type SelectableOptions = {
  key: string;
  options: SelectableOption[];
  errorMessage: string | undefined;
  headerText: string;
};

export type BookingDetailsViewModel = {
  serviceName: string;
  dateAndTime?: string;
  paymentDescription?: string;
  selectableOptions: SelectableOptions[];
  bookingDetailsPreferencesHeaderText: string;
  bookingDetailsClearText: string;
  bookingDetailsBookNowText: string;
  videoConferenceBadgeText?: string;
};

export async function createBookingDetailsViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Promise<BookingDetailsViewModel> {
  const { businessInfo, t, settings, wixSdkAdapter } = context;
  const {
    selectedService,
    selectableSlots,
    selectedTime,
    selectedOptions,
    calendarErrors,
  } = state;

  const [
    bookingDetailsPreferencesHeaderText,
    bookingDetailsStaffMemberDropDownText,
    bookingDetailsLocationDropDownText,
    bookingDetailsDurationDropDownText,
    bookingDetailsPricingPlanText,
    bookingDetailsClearText,
    bookingDetailsBookNowText,
  ] = getSettingsTexts(settings);

  const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale!;
  const serviceName = selectedService.info.name;

  const paymentDescription = await getPaymentDescription(
    wixSdkAdapter,
    selectedService.payment,
    dateRegionalSettingsLocale,
    bookingDetailsPricingPlanText,
  );

  const defaultViewModel = {
    serviceName,
    paymentDescription,
    bookingDetailsPreferencesHeaderText,
    bookingDetailsClearText,
    bookingDetailsBookNowText,
  };

  let dateAndTime;
  const selectableOptions: SelectableOptions[] = [];
  let selectableLocations: SelectableOption[] = [];
  let selectableStaffMembers: SelectableOption[] = [];
  let selectableDurations: SelectableOption[] = [];
  if (selectableSlots && selectedTime) {
    dateAndTime = formatRFCtoDateAndTime(
      selectedTime,
      dateRegionalSettingsLocale,
    );
    [
      selectableStaffMembers,
      selectableLocations,
      selectableDurations,
    ] = getSelectables(
      selectableSlots,
      t,
      dateRegionalSettingsLocale,
      selectedOptions,
    );

    const staffMemberError =
      calendarErrors.indexOf(
        CalendarErrors.BOOKING_DETAILS_NO_SELECTED_STAFF_MEMBER,
      ) !== -1
        ? t('app.booking-details.dropdowns.error.staff-member.text')
        : '';
    updateSelectableOptions(
      selectableOptions,
      Dropdowns.STAFF_MEMBER,
      selectableStaffMembers,
      staffMemberError,
      bookingDetailsStaffMemberDropDownText,
    );
    const locationError =
      calendarErrors.indexOf(
        CalendarErrors.BOOKING_DETAILS_NO_SELECTED_LOCATION,
      ) !== -1
        ? t('app.booking-details.dropdowns.error.location.text')
        : '';
    updateSelectableOptions(
      selectableOptions,
      Dropdowns.LOCATION,
      selectableLocations,
      locationError,
      bookingDetailsLocationDropDownText,
    );
    const durationError =
      calendarErrors.indexOf(
        CalendarErrors.BOOKING_DETAILS_NO_SELECTED_DURATION,
      ) !== -1
        ? t('app.booking-details.dropdowns.error.duration.text')
        : '';
    updateSelectableOptions(
      selectableOptions,
      Dropdowns.DURATION,
      selectableDurations,
      durationError,
      bookingDetailsDurationDropDownText,
    );
  }
  const videoConferenceBadgeText = selectedService.videoConferenceProviderId
    ? settings.get(settingsParams.videoConferenceBadgeText)
    : '';

  return {
    ...defaultViewModel,
    dateAndTime,
    selectableOptions,
    videoConferenceBadgeText,
  };
}
const updateSelectableOptions = (
  selectableOptions: SelectableOptions[],
  dropdownKey: Dropdowns,
  selectableSlotOptions: SelectableOption[],
  errorMessage: string,
  headerText: string,
) => {
  return selectableOptions.push({
    key: dropdownKey,
    options: selectableSlotOptions,
    errorMessage,
    headerText,
  });
};

const getPaymentDescription = async (
  wixSdkAdapter: WixOOISDKAdapter,
  payment: ServicePayment,
  dateRegionalSettingsLocale: string,
  bookingDetailsPricingPlanText: string,
) => {
  const paymentDescription = [];

  const isServiceBookableWithPricingPlan = await wixSdkAdapter.isPricingPlanInstalled();
  if (isOfferedAsOneTime(payment)) {
    const priceText = getPriceText(payment, dateRegionalSettingsLocale);
    paymentDescription.push(priceText);
  }
  if (
    isServiceOfferedAsPricingPlan(payment, isServiceBookableWithPricingPlan)
  ) {
    paymentDescription.push(bookingDetailsPricingPlanText);
  }
  return paymentDescription.join(' | ');
};

const isOfferedAsOneTime = (payment: ServicePayment) => {
  return payment.offeredAs.indexOf(OfferedAsType.ONE_TIME) >= 0;
};

const getPriceText = (
  payment: ServicePayment,
  dateRegionalSettingsLocale: string,
) => {
  const paymentDto: ServicePaymentDto = payment.paymentDetails;
  const paymentDtoMapper = new PaymentDtoMapper(dateRegionalSettingsLocale);
  return paymentDtoMapper.priceText(paymentDto);
};

const getSettingsTexts = (settings: { get: any }) => {
  const bookingDetailsPreferencesHeaderText = settings.get(
    settingsParams.bookingDetailsPreferencesHeaderText,
  );
  const bookingDetailsStaffMemberDropDownText = settings.get(
    settingsParams.bookingDetailsStaffMemberDropDownText,
  );
  const bookingDetailsLocationDropDownText = settings.get(
    settingsParams.bookingDetailsLocationDropDownText,
  );
  const bookingDetailsDurationDropDownText = settings.get(
    settingsParams.bookingDetailsDurationDropDownText,
  );
  const bookingDetailsPricingPlanText = settings.get(
    settingsParams.bookingDetailsPricingPlanText,
  );
  const bookingDetailsClearText = settings.get(
    settingsParams.bookingDetailsClearText,
  );
  const bookingDetailsBookNowText = settings.get(
    settingsParams.bookingDetailsBookNowText,
  );

  return [
    bookingDetailsPreferencesHeaderText,
    bookingDetailsStaffMemberDropDownText,
    bookingDetailsLocationDropDownText,
    bookingDetailsDurationDropDownText,
    bookingDetailsPricingPlanText,
    bookingDetailsClearText,
    bookingDetailsBookNowText,
  ];
};
