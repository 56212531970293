import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { OnDateSelectedAction } from '../onDateSelectedAction/onDateSelectedAction';
import { OnMonthSelectedAction } from '../onMonthSelectedAction/onMonthSelectedAction';
import { TriggeredByOptions } from '../../../../utils/bi/consts';

export type RefetchPlatformDataAction = (
  triggeredBy: TriggeredByOptions,
) => Promise<void>;

export function createRefetchPlatformDataAction(
  { getControllerState }: ActionFactoryParams<CalendarState, CalendarContext>,
  onDateSelectedAction: OnDateSelectedAction,
  onMonthSelectedAction: OnMonthSelectedAction,
): RefetchPlatformDataAction {
  return async (triggeredBy) => {
    const [state] = getControllerState();
    await Promise.all([
      onDateSelectedAction(state.selectedDate!, triggeredBy),
      onMonthSelectedAction(state.selectedMonth!, triggeredBy),
    ]);
  };
}
