import { Service } from '@wix/bookings-uou-types';
import { CalendarState } from '../../components/BookingCalendar/controller';
import {
  BottomSectionStatus,
  NotificationStatus,
} from '../../components/BookingCalendar/ViewModel/widgetViewModel/widgetViewModel';
import { getLocalTimezone } from '../dateAndTime/dateAndTime';
import { TimePickerStatus } from '../../components/BookingCalendar/ViewModel/timePickerViewModel/timePickerViewModel';

export function createInitialState(service: Service): CalendarState {
  return {
    bottomSectionStatus: BottomSectionStatus.LOADING,
    timePickerStatus: TimePickerStatus.LOADING,
    selectedService: service,
    selectedDate: undefined,
    selectedTimezone: getLocalTimezone(),
    selectedTime: undefined,
    availableSlots: undefined,
    selectableSlots: undefined,
    selectedMonth: undefined,
    dateAvailability: undefined,
    selectedOptions: undefined,
    notificationStatus: NotificationStatus.NONE,
    calendarErrors: [],
  };
}
