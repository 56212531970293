import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  TriggeredByOptions,
  WidgetComponents,
  WidgetElements,
} from '../../../../utils/bi/consts';
import { SetSelectedTimeAction } from '../setSelectedTimeAction/setSelectedTimeAction';

export type OnTimeSelectedAction = (selectedTime: string) => void;

export function createOnTimeSelectedAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedTimeAction: SetSelectedTimeAction,
): OnTimeSelectedAction {
  return async (selectedTime: string) => {
    const { biLogger } = context;

    setSelectedTimeAction(selectedTime);

    void biLogger.bookingsCalendarClick({
      element: WidgetElements.TIME_SLOT,
      component: WidgetComponents.TIME_PICKER,
      properties: JSON.stringify({
        selectedTime,
      }),
    });
    void biLogger.bookingsCalendarBookingDetailsLoad({
      triggeredBy: TriggeredByOptions.TIME_SELECTED,
      selectedSlot: selectedTime,
    });
  };
}
