import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { NotificationStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';
import {
  getSelectables,
  SelectableOption,
} from '../../../../utils/selectableOptions/selectableOptions';
import { updateCalendarErrors } from './calendarErrorsHandler';

export type OnSubmitAction = () => void;

export function createOnSubmitAction({
  getControllerState,
  context: { t, businessInfo, biLogger },
}: ActionFactoryParams<CalendarState, CalendarContext>): OnSubmitAction {
  return async () => {
    const [state, setState] = getControllerState();
    const {
      selectedTime,
      selectableSlots,
      selectedOptions,
      calendarErrors,
    } = state;

    if (!selectedTime) {
      setState({
        notificationStatus: NotificationStatus.NO_TIME_SELECTED_ERROR,
      });
    }
    let selectableStaffMembers: SelectableOption[] = [];
    let selectableLocations: SelectableOption[] = [];
    let selectableDurations: SelectableOption[] = [];
    const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale!;
    if (selectableSlots && selectedTime) {
      [
        selectableStaffMembers,
        selectableLocations,
        selectableDurations,
      ] = getSelectables(
        selectableSlots,
        t,
        dateRegionalSettingsLocale,
        selectedOptions,
      );

      updateCalendarErrors(
        calendarErrors,
        selectableStaffMembers,
        selectableLocations,
        selectableDurations,
        biLogger,
        selectedTime,
        selectedOptions,
      );
    }

    setState({
      calendarErrors,
    });
  };
}
