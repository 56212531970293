import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { formatShortDate } from '../../../../utils/dateAndTime/dateAndTime';
import { CalendarState } from '../../controller';

export type RescheduleDetailsViewModel = {
  notificationContent: string;
  buttonText: string;
};

export function createRescheduleDetailsViewModel({
  state,
  context,
}: ViewModelFactoryParams<CalendarState, CalendarContext>):
  | RescheduleDetailsViewModel
  | undefined {
  if (!state.rescheduleBookingDetails) {
    return;
  }

  const { rescheduleBookingDetails } = state;
  const { t, businessInfo } = context;

  const notificationContent = t('app.rescheduled-booking.content', {
    participants_amount: 2,
    date: formatShortDate(
      rescheduleBookingDetails.bookedEntity?.singleSession?.start!,
      businessInfo.dateRegionalSettingsLocale,
    ),
  });
  const buttonText = t('app.rescheduled-booking.back-button');

  return {
    notificationContent,
    buttonText,
  };
}
