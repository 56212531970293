import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SelectedOptions } from '../../../../utils/selectableOptions/selectableOptions';
import { CalendarErrors, Dropdowns } from '../../../../utils/bi/consts';

export type OnSlotOptionSelectedAction = (
  selectedOptions: SelectedOptions,
) => Promise<void>;

export function createOnSlotOptionSelectedAction({
  getControllerState,
}: ActionFactoryParams<
  CalendarState,
  CalendarContext
>): OnSlotOptionSelectedAction {
  return async (selectedOptions) => {
    const [state, setState] = getControllerState();
    const {
      selectedOptions: prevSelectedOptions,
      calendarErrors: prevCalendarErrors,
    } = state;

    let calendarErrors: CalendarErrors[] = [];
    Object.keys(selectedOptions).forEach((selectablOption) => {
      calendarErrors = removeSelectedOptionFromCalendarErrors(
        selectablOption,
        prevCalendarErrors,
      );
    });

    setState({
      calendarErrors,
      selectedOptions: {
        ...prevSelectedOptions,
        ...selectedOptions,
      },
    });
  };
}
const removeSelectedOptionFromCalendarErrors = (
  selectedOption: string,
  calendarErrors: CalendarErrors[],
) => {
  let calendarError: CalendarErrors = CalendarErrors.NONE;
  switch (selectedOption) {
    case Dropdowns.STAFF_MEMBER:
      calendarError = CalendarErrors.BOOKING_DETAILS_NO_SELECTED_STAFF_MEMBER;
      break;
    case Dropdowns.LOCATION:
      calendarError = CalendarErrors.BOOKING_DETAILS_NO_SELECTED_LOCATION;
      break;
    case Dropdowns.DURATION:
      calendarError = CalendarErrors.BOOKING_DETAILS_NO_SELECTED_DURATION;
      break;
  }
  if (calendarError !== CalendarErrors.NONE) {
    const index = calendarErrors.indexOf(calendarError);
    if (index > -1) {
      calendarErrors.splice(index, 1);
    }
  }
  return calendarErrors;
};
