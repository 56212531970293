import {
  Slot,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import {
  DurationMapper,
  DurationMapperOptions,
} from '@wix/bookings-uou-mappers';
import { DropdownOptionProps } from 'wix-ui-tpa/Dropdown';
import { TFunction } from '../../components/BookingCalendar/controller';
import { minutesDifferencesBetweenTwoDates } from '../dateAndTime/dateAndTime';

export type SelectableOption = {
  id?: string;
  value?: string;
  isSelectable?: boolean;
};

export type SelectedOptions = {
  location?: string;
  staffMember?: string;
  duration?: string;
};

const getDropDownSubtitle = (dropdownSubtitle: string) => {
  return {
    id: dropdownSubtitle,
    value: dropdownSubtitle,
    isSelectable: false,
    isSectionTitle: true,
  };
};
export const mapSelectableOptionsToDropdownOptions = (
  selectableOptions: SelectableOption[],
  subtitle: string,
): DropdownOptionProps[] => {
  const dropdownOptions: DropdownOptionProps[] = selectableOptions.map(
    (selectableOption) => {
      return {
        id: selectableOption.id,
        value: selectableOption.value,
        isSelectable: selectableOption.isSelectable,
        isSectionTitle: false,
      };
    },
  );
  if (selectableOptions.length > 1) {
    const dropdownSubtitle = getDropDownSubtitle(subtitle);
    dropdownOptions.unshift(dropdownSubtitle);
  }

  return dropdownOptions;
};

export const getSelectables = (
  selectableSlots: SlotAvailability[],
  t: TFunction,
  dateRegionalSettingsLocale: string,
  selectedOptions?: SelectedOptions,
): SelectableOption[][] => {
  let selectableStaffMembers: SelectableOption[] = [];
  let selectableLocations: SelectableOption[] = [];
  let selectableDurations: SelectableOption[] = [];
  selectableStaffMembers = getSelectableStaffMembers(
    selectableSlots,
    t,
    dateRegionalSettingsLocale,
    selectedOptions,
  );
  selectableLocations = getSelectableLocations(
    selectableSlots,
    t,
    dateRegionalSettingsLocale,
    selectedOptions,
  );
  selectableDurations = getSelectableDurations(
    selectableSlots,
    t,
    dateRegionalSettingsLocale,
    selectedOptions,
  );
  return [selectableStaffMembers, selectableLocations, selectableDurations];
};

export const getSelectableLocations = (
  selectableSlots: SlotAvailability[],
  t: TFunction,
  dateRegionalSettingsLocale: string,
  selectedOptions?: SelectedOptions,
): SelectableOption[] => {
  const filteredSelectableLocation: SlotAvailability[] = [];
  selectableSlots.forEach((selectableSlot) => {
    const locationId = selectableSlot.slot?.location?.id!;
    const isLocationExist = filteredSelectableLocation.some(
      (selectableLocation) =>
        selectableLocation.slot?.location?.id === locationId,
    );
    !isLocationExist && filteredSelectableLocation.push(selectableSlot);
  });

  const selectableLocations = filteredSelectableLocation.map(
    (selectableSlot: SlotAvailability) => {
      const locationName = selectableSlot.slot?.location?.name!;
      const locationId = selectableSlot.slot?.location?.id;
      return {
        id: locationId,
        value: locationName,
        isSelectable: isLocationOptionSelectable(
          t,
          dateRegionalSettingsLocale,
          selectableSlot.slot,
          selectedOptions,
        ),
      };
    },
  );

  return selectableLocations;
};

export const getSelectableStaffMembers = (
  selectableSlots: SlotAvailability[],
  t: TFunction,
  dateRegionalSettingsLocale: string,
  selectedOptions?: SelectedOptions,
): SelectableOption[] => {
  const selectableStaffMembersFiltered: SlotAvailability[] = [];
  selectableSlots.forEach((selectableSlot) => {
    const staffMemberId = selectableSlot.slot?.resource?.id;
    const isStaffMemberExist = selectableStaffMembersFiltered.some(
      (member) => member.slot?.resource?.id === staffMemberId,
    );
    !isStaffMemberExist && selectableStaffMembersFiltered.push(selectableSlot);
  });

  const selectableStaffMembers = selectableStaffMembersFiltered.map(
    (selectableSlot: SlotAvailability) => {
      const staffMemberName = selectableSlot.slot?.resource?.name!;
      const staffMemberId = selectableSlot.slot?.resource?.id;

      return {
        id: staffMemberId,
        value: staffMemberName,
        isSelectable: isStaffMemberOptionSelectable(
          t,
          dateRegionalSettingsLocale,
          selectableSlot.slot,
          selectedOptions,
        ),
      };
    },
  );

  return selectableStaffMembers;
};

export const getSelectableDurations = (
  selectableSlots: SlotAvailability[],
  t: TFunction,
  dateRegionalSettingsLocale: string,
  selectedOptions?: SelectedOptions,
): SelectableOption[] => {
  const selectableDurations = selectableSlots.map(
    (selectableSlot: SlotAvailability) => {
      const startTime = selectableSlot.slot?.start!;
      const endTime = selectableSlot.slot?.end!;
      const slotDuration = getSlotDuration(
        startTime,
        endTime,
        t,
        dateRegionalSettingsLocale,
      );

      return {
        id: slotDuration,
        value: slotDuration,
        isSelectable: isDurationOptionSelectable(
          selectableSlot.slot,
          selectedOptions,
        ),
      };
    },
  );

  const selectableDurationsFiltered: SelectableOption[] = [];
  selectableDurations.forEach((duration) => {
    const isDurationExist = selectableDurationsFiltered.some(
      (selectableDuration) => selectableDuration.value === duration.value,
    );
    !isDurationExist && selectableDurationsFiltered.push(duration);
  });
  return selectableDurationsFiltered;
};

const isLocationOptionSelectable = (
  t: TFunction,
  dateRegionalSettingsLocale: string,
  slot?: Slot,
  selectedOptions?: SelectedOptions,
) => {
  return (
    filterSlotByStaffMemberSelectedOption(slot, selectedOptions) &&
    filterSlotByDurationSelectedOption(
      t,
      dateRegionalSettingsLocale,
      slot,
      selectedOptions,
    )
  );
};

const isStaffMemberOptionSelectable = (
  t: TFunction,
  dateRegionalSettingsLocale: string,
  slot?: Slot,
  selectedOptions?: SelectedOptions,
) => {
  return (
    filterSlotByLocationSelectedOption(slot, selectedOptions) &&
    filterSlotByDurationSelectedOption(
      t,
      dateRegionalSettingsLocale,
      slot,
      selectedOptions,
    )
  );
};

const isDurationOptionSelectable = (
  slot?: Slot,
  selectedOptions?: SelectedOptions,
) => {
  return (
    filterSlotByStaffMemberSelectedOption(slot, selectedOptions) &&
    filterSlotByLocationSelectedOption(slot, selectedOptions)
  );
};

export const filterSlotByLocationSelectedOption = (
  slot?: Slot,
  selectedOptions?: SelectedOptions,
) => {
  const locationId = slot?.location?.id;
  const locationSelectedOption = selectedOptions?.location;

  if (locationSelectedOption && locationId !== locationSelectedOption) {
    return false;
  }
  return true;
};

export const filterSlotByStaffMemberSelectedOption = (
  slot?: Slot,
  selectedOptions?: SelectedOptions,
) => {
  const staffMemberId = slot?.resource?.id;
  const staffMemberSelectedOption = selectedOptions?.staffMember;

  if (
    staffMemberSelectedOption &&
    staffMemberId !== staffMemberSelectedOption
  ) {
    return false;
  }
  return true;
};

export const filterSlotByDurationSelectedOption = (
  t: TFunction,
  dateRegionalSettingsLocale: string,
  slot?: Slot,
  selectedOptions?: SelectedOptions,
) => {
  const startDate = slot?.start!;
  const endDate = slot?.end!;
  const duration = getSlotDuration(
    startDate,
    endDate,
    t,
    dateRegionalSettingsLocale,
  );

  const durationSelectedOption = selectedOptions?.duration;
  if (durationSelectedOption && duration !== durationSelectedOption) {
    return false;
  }
  return true;
};
export const getSlotDuration = (
  startTime: string,
  endTime: string,
  t: TFunction,
  dateRegionalSettingsLocale: string,
): string => {
  const durationInMinutes = minutesDifferencesBetweenTwoDates(
    startTime,
    endTime,
  );

  const durationOptions: DurationMapperOptions = {
    hourUnit: 'duration.units.hours',
    hourAriaUnit: 'duration.units.aria-hours',
    minuteUnit: 'duration.units.minutes',
    minuteAriaUnit: 'duration.units.aria-minutes',
  };

  const durationMapper = new DurationMapper(
    dateRegionalSettingsLocale,
    durationOptions,
    t,
  );

  return durationMapper.durationTextFromMinutes(durationInMinutes);
};
