import { Booking } from '@wix/ambassador-bookings-server/types';
import { ControllerParams, CreateControllerFn } from 'yoshi-flow-editor';
import { createControlledComponent } from '../../utils/ControlledComponent/ControlledComponent';
import {
  CalendarViewModel,
  createCalendarViewModel,
} from './ViewModel/viewModel';
import { CalendarActions, createCalendarActions } from './Actions/actions';
import { CatalogData, Service } from '@wix/bookings-uou-types';
import {
  CalendarContext,
  createCalendarContext,
} from '../../utils/context/contextFactory';
import { CalendarApi } from '../../api/CalendarApi';
import { createWixSdkAdapter } from '../../utils/sdkAdapterFactory';
import { createInitialState } from '../../utils/state/initialStateFactory';
import {
  BottomSectionStatus,
  NotificationStatus,
} from './ViewModel/widgetViewModel/widgetViewModel';
import {
  QueryAvailabilityResponse,
  QueryDateAvailabilityResponse,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { TimePickerStatus } from './ViewModel/timePickerViewModel/timePickerViewModel';
import { SelectedOptions } from '../../utils/selectableOptions/selectableOptions';
import { CalendarErrors } from '../../utils/bi/consts';

export type TFunction = (
  key: string | string[],
  options?: Record<string, any>,
  defaultValue?: string,
) => string;

export type CalendarState = {
  bottomSectionStatus: BottomSectionStatus;
  timePickerStatus: TimePickerStatus;
  selectedService: Service;
  selectedDate?: string;
  selectedTimezone?: string;
  selectedMonth?: string;
  dateAvailability?: QueryDateAvailabilityResponse;
  selectedTime?: string;
  selectableSlots?: SlotAvailability[];
  availableSlots?: QueryAvailabilityResponse;
  selectedOptions?: SelectedOptions;
  notificationStatus: NotificationStatus;
  calendarErrors: CalendarErrors[];
  rescheduleBookingDetails?: Booking;
};

// For more info about controller structure,
// check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller
const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  return {
    async pageReady() {
      const { controllerConfig } = flowAPI;
      const wixSdkAdapter = createWixSdkAdapter(controllerConfig);
      const calendarApi = new CalendarApi({ wixSdkAdapter });
      const {
        services: [service],
        businessInfo,
      }: CatalogData = await calendarApi.getCatalogData();

      const initialState: CalendarState = createInitialState(service);

      const calendarContext: CalendarContext = createCalendarContext(
        flowAPI,
        businessInfo,
        calendarApi,
        wixSdkAdapter,
        initialState,
      );

      const { onStateChange } = await createControlledComponent<
        CalendarState,
        CalendarActions,
        CalendarViewModel,
        CalendarContext
      >(
        controllerConfig,
        initialState,
        createCalendarViewModel,
        createCalendarActions,
        calendarContext,
      );

      const { biLogger } = calendarContext;
      if (!wixSdkAdapter.isSSR()) {
        onStateChange((state) => {
          biLogger.update(state);
        });
      }
    },
  };
};

export default createController;
