import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { TriggeredByOptions } from '../../../../utils/bi/consts';
import { RefetchPlatformDataAction } from '../refetchPlatformDataAction/refetchPlatformDataAction';
import { SetSelectedTimeAction } from '../setSelectedTimeAction/setSelectedTimeAction';

export type OnTimezoneSelectedAction = (timezone: string) => Promise<void>;

export function createOnTimezoneSelectedAction(
  { getControllerState }: ActionFactoryParams<CalendarState, CalendarContext>,
  refetchPlatformData: RefetchPlatformDataAction,
  setSelectedTimeAction: SetSelectedTimeAction,
): OnTimezoneSelectedAction {
  return async (timezone: string) => {
    const [, setState] = getControllerState();

    setState({
      selectedTimezone: timezone,
    });

    setSelectedTimeAction(undefined);

    await refetchPlatformData(TriggeredByOptions.TIMEZONE_CHANGED);
  };
}
