import { Booking } from '@wix/ambassador-bookings-server/types';
import { CatalogData } from '@wix/bookings-uou-types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { QueryAvailabilityResponse } from '@wix/ambassador-availability-calendar/types';
import { mockGetSlotsForSelectedDate } from '../../__tests__/mocks/ambassador/mockGetSlotsForSelectedDate';
import { getFormattedDateString } from '../utils/dateAndTime/dateAndTime';
import { createDummyCatalogData } from './dummyData/dummyCatalogData';
import { BookingsApi } from './BookingsApi';
import { CalendarApiInitParams, DateAvailabilityFilter } from './types';

const CALENDAR_PAGE_URL_PATH_PARAM = 'booking-calendar';

export class CalendarApi {
  private wixSdkAdapter: WixOOISDKAdapter;
  private bookingsApi: BookingsApi;

  constructor({ wixSdkAdapter }: CalendarApiInitParams) {
    this.wixSdkAdapter = wixSdkAdapter;
    this.bookingsApi = new BookingsApi({
      authorization: wixSdkAdapter.getInstance(),
      baseUrl: wixSdkAdapter.getServerBaseUrl(),
    });
  }

  async getCatalogData(): Promise<CatalogData> {
    if (this.wixSdkAdapter.isEditorMode()) {
      return createDummyCatalogData();
    }

    const serviceSlug = await this.wixSdkAdapter.getServiceSlug(
      CALENDAR_PAGE_URL_PATH_PARAM,
    );
    const catalogData = await this.bookingsApi.getCatalogData({ serviceSlug });

    return catalogData;
  }

  async getNextAvailableDate({ from }: DateAvailabilityFilter) {
    const dateAvailabilityResponse = await this.getDateAvailability({ from });
    const nextAvailableDate = dateAvailabilityResponse.nextAvailable?.date;
    const formattedNextAvailableDate =
      nextAvailableDate && getFormattedDateString(nextAvailableDate);

    return formattedNextAvailableDate;
  }

  async getDateAvailability({ from }: DateAvailabilityFilter) {
    const dateAvailabilityResponse = await this.bookingsApi.getDateAvailability(
      { from },
    );

    return dateAvailabilityResponse;
  }

  async getSlotsForSelectedDate(
    date: string,
  ): Promise<QueryAvailabilityResponse> {
    return mockGetSlotsForSelectedDate(date);
  }

  async getBookingDetails(bookingId: string): Promise<Booking | undefined> {
    return this.bookingsApi.getBookingDetails(bookingId);
  }
}
