import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import settingsParams from '../../settingsParams';

export enum NotificationStatus {
  NO_TIME_SELECTED_ERROR = 'NO_TIME_SELECTED_ERROR',
  NONE = 'NONE',
}

export enum BottomSectionStatus {
  LOADING = 'LOADING',
  NO_SERVICE_AVAILABILITY = 'NO_SERVICE_AVAILABILITY',
  LOADED = 'LOADED',
}

export type WidgetViewModel = {
  status: BottomSectionStatus;
  dateAndTimeSectionHeaderText: string;
  bookingDetailsSectionHeaderText: string;
  notificationStatus: NotificationStatus;
  notificationText: string;
};

export function createWidgetViewModel({
  state,
  context: { t, settings },
}: ViewModelFactoryParams<CalendarState, CalendarContext>): WidgetViewModel {
  const { notificationStatus } = state;

  const notificationText = getNotificationText(notificationStatus, t);

  return {
    status: state.bottomSectionStatus,
    dateAndTimeSectionHeaderText: settings.get(
      settingsParams.dateAndTimeSectionHeaderText,
    ),
    bookingDetailsSectionHeaderText: settings.get(
      settingsParams.bookingDetailsSectionHeaderText,
    ),
    notificationStatus,
    notificationText,
  };
}

const getNotificationText = (
  notificationStatus: NotificationStatus,
  t: TFunction,
) => {
  if (notificationStatus === NotificationStatus.NO_TIME_SELECTED_ERROR) {
    return t('app.widget.notification.no-time-selected-error.text');
  }
  return '';
};
